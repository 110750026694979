import React, {Component} from 'react';
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import Logger from "../../../core/util/Logger";
import {popupDisplayHandlerAction} from "../../../actions/popup-display-action";
import {ErrorMessage, Field, FieldArray, Form, Formik} from "formik";
import * as Yup from "yup";
import {resetReduxStoreAction} from "../../../actions/reset-redux-store-action";
import {adminCreateVideoProcessingProjectAction} from "../../../actions/admin/admin-create-video-processing-project-action";
import {storeVideoProcessingProjectAction} from "../../../actions/admin/store-video-processing-project-action";
import LoadingSpinner from "../../loading-spinner/LoadingSpinner";

let defaultState = {submit_video_processing: false, show_video_processing_form: true, vimeo_token_input:true, message: null};

class MemberVideoProcessingPopup extends Component {

    constructor() {
        super();
        this.state = defaultState;
        this.formik = React.createRef();
    }

    render() {
        if (this.props.member_profile && this.props.member_profile.success) {
            return (
                <div>
                    <LoadingSpinner/>
                    <div tabIndex="-1" role="dialog"
                         style={this.props.popup.data.id === 'member_video_processing_popup' && this.props.popup.data.shouldDisplay ? {display: 'block'} : {display: 'none'}}
                         className="modal fade show"
                         aria-modal="true">
                        <div className="overlay">
                            <div role="document" className="modal-dialog">
                                <div className="modal-content">


                                    <div className="modal-header">
                                        <h5 id="exampleModalLabel" className="modal-title text-center">
                                            Start Video Processing
                                        </h5>

                                        <button type="button" data-dismiss="modal" aria-label="Close"
                                                onClick={this.onClosePopupHandler.bind(this)}
                                                className="close">
                                            <span aria-hidden="true">×</span></button>
                                    </div>


                                    <div style={this.state.message !== null ? {display: 'block'} : {display: 'none'}}
                                         className={(this.props.admin_create_video_processing_project != null && this.props.admin_create_video_processing_project.success === true) ? "alert alert-success" : "alert alert-danger"}
                                         role="alert">
                                        {this.state.message}
                                    </div>

                                    <div
                                        style={this.state.show_video_processing_form === true ? {display: 'block'} : {display: 'none'}}>

                                        <Formik
                                            ref={this.formik}
                                            initialValues={
                                                {
                                                    name: this.makeProjectName(),
                                                    service_configuration: {
                                                        vimeo: {
                                                            trainer_id: "",
                                                            token: this.getVimeoToken(),
                                                            client_id: "",
                                                            client_secret: "",
                                                            of_user: "",
                                                            of_folder: ""
                                                        },
                                                        platform: {
                                                            url: "",
                                                            email: this.makePlatformEmail(),
                                                            password: this.makePlatformPassword()
                                                        }
                                                    },
                                                    stakeholders: [this.props.user.data.email]
                                                }
                                            }
                                            validationSchema={Yup.object().shape({
                                                name: Yup.string()
                                                    .required('Project Name is required'),
                                                service_configuration: Yup.object({
                                                    vimeo: Yup.object({
                                                        trainer_id: Yup.string().nullable(),
                                                        token: Yup.string().when("trainer_id", {
                                                            is: '',
                                                            then: Yup.string().required("Token is required")
                                                        }),
                                                        client_id: Yup.string(),
                                                        client_secret: Yup.string(),
                                                        of_user: Yup.string(),
                                                        of_folder: Yup.string()
                                                    }),
                                                    platform: Yup.object({
                                                        url: Yup.string().required('Trainer Console URL is required'),
                                                        email: Yup.string().required('Login Email is required'),
                                                        password: Yup.string().required('Platform Password is required')
                                                    }),
                                                }),
                                                stakeholders: Yup.array().of(Yup.string().email())
                                            })}
                                            onSubmit={fields => {
                                                Logger.info("handle submission");
                                                this.onSubmitHandler(fields);
                                            }}
                                            render={({values, errors, status, touched}) => (
                                                <div>
                                                    <Form>
                                                        <div className="modal-body"
                                                             style={{
                                                                 'maxHeight': 'calc(100vh - 210px)',
                                                                 'overflowY': 'auto'
                                                             }}>
                                                            <div className="container">
                                                                <fieldset>
                                                                    <div className="form-group">
                                                                        <label>Project Name</label>
                                                                        <Field required name="name" type="text"
                                                                               className={'form-control' + (errors.name ? ' is-invalid' : '')}/>
                                                                        <ErrorMessage name="name" component="div"
                                                                                      className="invalid-feedback"/>
                                                                    </div>
                                                                </fieldset>
                                                                <fieldset>
                                                                    <legend>MacroActive Platform</legend>
                                                                    <div className="form-group">
                                                                        <label>Trainer Console URL</label>
                                                                        <Field name="service_configuration.platform.url"
                                                                               type="text"
                                                                               className={'form-control' + (errors.service_configuration
                                                                               && errors.service_configuration.platform
                                                                               && errors.service_configuration.platform.url
                                                                               && touched.service_configuration
                                                                               && touched.service_configuration.platform
                                                                               && touched.service_configuration.platform.url ? ' is-invalid' : '')}/>
                                                                        <ErrorMessage
                                                                            name="service_configuration.platform.url"
                                                                            component="div"
                                                                            className="invalid-feedback"/>
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label>Create a user in above console with
                                                                            following details</label>
                                                                        <Field
                                                                            name="service_configuration.platform.email"
                                                                            type="email"
                                                                            className={'text-center form-control' + (errors.service_configuration
                                                                            && errors.service_configuration.platform
                                                                            && errors.service_configuration.platform.email
                                                                            && touched.service_configuration
                                                                            && touched.service_configuration.platform
                                                                            && touched.service_configuration.platform.email ? ' is-invalid' : '')}/>
                                                                        <ErrorMessage
                                                                            name="service_configuration.platform.email"
                                                                            component="div"
                                                                            className="invalid-feedback"/>
                                                                        <div className="mt-1 form-group">
                                                                            <Field
                                                                                name="service_configuration.platform.password"
                                                                                type="text"
                                                                                className={'text-center form-control' + (errors.service_configuration
                                                                                && errors.service_configuration.platform
                                                                                && errors.service_configuration.platform.password
                                                                                && touched.service_configuration
                                                                                && touched.service_configuration.platform
                                                                                && touched.service_configuration.platform.password ? ' is-invalid' : '')}/>
                                                                            <ErrorMessage
                                                                                name="service_configuration.platform.password"
                                                                                component="div"
                                                                                className="invalid-feedback"/>
                                                                        </div>
                                                                    </div>
                                                                </fieldset>
                                                                <fieldset>
                                                                    <legend>Vimeo</legend>
                                                                    <ul className="nav nav-tabs" id="myTab"
                                                                        role="tablist">
                                                                        <li className="nav-item" role="presentation">
                                                                            <button className="nav-link active"
                                                                                    style={{outline: 'none'}}
                                                                                    id="token-tab" data-toggle="tab"
                                                                                    data-target="#token" type="button"
                                                                                    role="tab" aria-controls="token"
                                                                                    aria-selected="true">Token
                                                                            </button>
                                                                        </li>
                                                                        <li className="nav-item" role="presentation">
                                                                            <button className="nav-link"
                                                                                    style={{outline: 'none'}}
                                                                                    id="trainer-id-tab" data-toggle="tab"
                                                                                    data-target="#trainerId" type="button"
                                                                                    role="tab" aria-controls="trainerId"
                                                                                    aria-selected="false">Trainer ID
                                                                            </button>
                                                                        </li>
                                                                    </ul>
                                                                    <div className="tab-content" id="myTabContent">
                                                                        <div className="tab-pane fade show active mt-2" id="token"
                                                                             role="tabpanel"
                                                                             aria-labelledby="token-tab">
                                                                            {this.state.vimeo_token_input &&
                                                                            <div className="form-group">
                                                                                <label>Token</label>
                                                                                <Field
                                                                                       name="service_configuration.vimeo.token"
                                                                                       type="text"
                                                                                       className={'form-control' + (errors.service_configuration
                                                                                       && errors.service_configuration.vimeo
                                                                                       && errors.service_configuration.vimeo.token
                                                                                       && touched.service_configuration
                                                                                       && touched.service_configuration.vimeo
                                                                                       && touched.service_configuration.vimeo.token ? ' is-invalid' : '')}/>
                                                                                <ErrorMessage
                                                                                  name="service_configuration.vimeo.token"
                                                                                  component="div"
                                                                                  className="invalid-feedback"/>
                                                                            </div>
                                                                            }
                                                                            <div className="form-row">
                                                                                <div className="col">
                                                                                    <div className="form-group">
                                                                                        <label>User</label>
                                                                                        <Field
                                                                                          name="service_configuration.vimeo.of_user"
                                                                                          type="text"
                                                                                          className={'form-control' + (errors.service_configuration
                                                                                          && errors.service_configuration.vimeo
                                                                                          && errors.service_configuration.vimeo.of_user
                                                                                          && touched.service_configuration
                                                                                          && touched.service_configuration.vimeo
                                                                                          && touched.service_configuration.vimeo.of_user ? ' is-invalid' : '')}/>
                                                                                        <ErrorMessage
                                                                                          name="service_configuration.vimeo.of_user"
                                                                                          component="div"
                                                                                          className="invalid-feedback"/>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col">
                                                                                    <div className="form-group">
                                                                                        <label>Folder</label>
                                                                                        <Field
                                                                                          name="service_configuration.vimeo.of_folder"
                                                                                          type="text"
                                                                                          className={'form-control' + (errors.service_configuration
                                                                                          && errors.service_configuration.vimeo
                                                                                          && errors.service_configuration.vimeo.of_folder
                                                                                          && touched.service_configuration
                                                                                          && touched.service_configuration.vimeo
                                                                                          && touched.service_configuration.vimeo.of_folder ? ' is-invalid' : '')}/>
                                                                                        <ErrorMessage
                                                                                          name="service_configuration.vimeo.of_folder"
                                                                                          component="div"
                                                                                          className="invalid-feedback"/>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="tab-pane fade mt-2"
                                                                             id="trainerId" role="tabpanel"
                                                                             aria-labelledby="trainer-id-tab">
                                                                            <div className="form-group">
                                                                                <label>Trainer ID (xxx-xxx or xxx.xxx)</label>
                                                                                <Field
                                                                                  name="service_configuration.vimeo.trainer_id"
                                                                                  type="text"
                                                                                  className={'form-control' + (errors.service_configuration
                                                                                  && errors.service_configuration.vimeo
                                                                                  && errors.service_configuration.vimeo.trainer_id
                                                                                  && touched.service_configuration
                                                                                  && touched.service_configuration.vimeo
                                                                                  && touched.service_configuration.vimeo.trainer_id ? ' is-invalid' : '')}/>
                                                                                <ErrorMessage
                                                                                  name="service_configuration.vimeo.trainer_id"
                                                                                  component="div"
                                                                                  className="invalid-feedback"/>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label className="d-none">Client ID</label>
                                                                        <Field
                                                                            name="service_configuration.vimeo.client_id"
                                                                            type="hidden"
                                                                            className={'form-control' + (errors.service_configuration
                                                                            && errors.service_configuration.vimeo
                                                                            && errors.service_configuration.vimeo.client_id
                                                                            && touched.service_configuration
                                                                            && touched.service_configuration.vimeo
                                                                            && touched.service_configuration.vimeo.client_id ? ' is-invalid' : '')}/>
                                                                        <ErrorMessage
                                                                            name="service_configuration.vimeo.client_id"
                                                                            component="div"
                                                                            className="invalid-feedback"/>
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label className="d-none">Client Secret</label>
                                                                        <Field
                                                                            name="service_configuration.vimeo.client_secret"
                                                                            type="hidden"
                                                                            className={'form-control' + (errors.service_configuration
                                                                            && errors.service_configuration.vimeo
                                                                            && errors.service_configuration.vimeo.client_secret
                                                                            && touched.service_configuration
                                                                            && touched.service_configuration.vimeo
                                                                            && touched.service_configuration.vimeo.client_secret ? ' is-invalid' : '')}/>
                                                                        <ErrorMessage
                                                                            name="service_configuration.vimeo.client_secret"
                                                                            component="div"
                                                                            className="invalid-feedback"/>
                                                                    </div>
                                                                </fieldset>
                                                                <FieldArray
                                                                    name="stakeholders"
                                                                    render={arrayHelpers => (
                                                                        <div className="form-group">
                                                                            <legend>Stakeholders</legend>
                                                                            {values.stakeholders && values.stakeholders.length > 0 ? (
                                                                                values.stakeholders.map((friend, index) => (
                                                                                    <div key={index}
                                                                                         className="input-group mb-3">
                                                                                        <Field
                                                                                            name={`stakeholders.${index}`}
                                                                                            className="form-control"
                                                                                            aria-label="Recipient's username"
                                                                                            aria-describedby="basic-addon2"/>
                                                                                        <div
                                                                                            className="input-group-append">
                                                                                            <button
                                                                                                className="btn btn-outline-secondary"
                                                                                                type="button"
                                                                                                onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                                                                                            >
                                                                                                -
                                                                                            </button>
                                                                                            <button
                                                                                                className="btn btn-outline-secondary"
                                                                                                type="button"
                                                                                                onClick={() => arrayHelpers.insert(index + 1, '')} // insert an empty string at a position
                                                                                            >
                                                                                                +
                                                                                            </button>
                                                                                        </div>
                                                                                    </div>
                                                                                ))
                                                                            ) : (
                                                                                <div>
                                                                                    <button type="button"
                                                                                            className="btn btn-link"
                                                                                            onClick={() => arrayHelpers.push('')}>
                                                                                        {/* show this when user has removed all friends from the list */}
                                                                                        Add a Stakeholder
                                                                                    </button>
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    )}
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="modal-footer pt-0">

                                                            <button type={"submit"}
                                                                    className={'btn btn-primary'}>
                                                                Submit
                                                            </button>

                                                            <button type="button" data-dismiss="modal"
                                                                    onClick={this.onClosePopupHandler.bind(this)}
                                                                    className="btn btn-secondary">Close
                                                            </button>
                                                        </div>

                                                    </Form>
                                                </div>
                                            )}
                                        />
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return ('');
        }
    }

    onClosePopupHandler() {
        Logger.info("popup is closed");
        this.formik.current.resetForm();
        this.props.resetReduxStore('MEMBER_PROFILE_RESET');
        this.setState(defaultState);

        let payload = {
            id: "member_video_processing_popup",
            shouldDisplay: false
        };
        this.props.handlePopupVisibility(payload);
    }


    onSubmitHandler(data) {
        let userId = this.props.member_profile.data.user_id;
        let token = this.props.user.data.auth.token;
        if (this.props.member_profile.data.hid) {
            data.service_configuration.onboarding = {id: this.props.member_profile.data.hid}
        }
        this.setState({submit_video_processing: true, show_video_processing_form: false});
        this.props.createVideoProcessingProject(token, userId, data).then(() => {
            this.handleFormSubmit();
        });
    }

    handleFormSubmit() {
        if (this.props.admin_create_video_processing_project != null) {
            Logger.info("updated the message", this.props.admin_create_video_processing_project);
            if (this.props.admin_create_video_processing_project.success === true) {
                this.formik.current.resetForm();
                this.setState({
                    message: this.props.admin_create_video_processing_project.message,
                    submit_video_processing: false,
                });
                let projectId = this.props.admin_create_video_processing_project.data.id;
                let userId = this.props.member_profile.data.user_id;
                let token = this.props.user.data.auth.token;
                this.props.storeVideoProcessingProject(token, userId, projectId)
                    .then(() => {
                        if (this.props.store_video_processing_project !== null
                            && this.props.store_video_processing_project.success === true) {
                            let payload = {
                                id: "member_video_processing_popup",
                                shouldDisplay: false
                            };
                            this.props.handlePopupVisibility(payload);
                            this.props.history.push("/member/video-processing/" + projectId);
                        }
                    });
            } else {
                this.setState({
                    message: this.props.admin_create_video_processing_project.message,
                    submit_video_processing: false,
                    show_video_processing_form: true
                })
            }
        }
    }

    makeProjectName() {
        let name = '';
        if (this.props.member_profile && this.props.member_profile.data) {
            name += this.props.member_profile.data.full_name || '';
            if (name !== '' && this.props.member_profile.data.company_name) {
                name += ' - ';
            }
            name += this.props.member_profile.data.company_name || '';
        }
        return name;
    }

    getVimeoToken() {
        let vimeoToken = this.props.member_profile.data.vimeo_token;
        if (vimeoToken && this.state.vimeo_token_input) {
            this.setState({vimeo_token_input: false});
        }
        return vimeoToken ? vimeoToken : '';
    }


    makePlatformEmail() {
        return 'onboarding+loader-' + this.props.member_profile.data.user_id + '@macroactive.com';
    }

    makePlatformPassword() {
        let s = this.props.member_profile.data.hid;
        let hash = 0, i, chr;
        if (s.length === 0) return hash;
        for (i = 0; i < s.length; i++) {
            chr = s.charCodeAt(i);
            hash = ((hash << 10) - hash) + chr;
            hash |= 0; // Convert to 32bit integer
        }
        console.log(hash);
        hash = Math.abs(hash);
        hash = hash * 3143;
        return hash.toString(15);
    }
}


const mapStateToProps = (state) => {
    return {
        popup: state.popup,
        member_profile: state.member_profile,
        user: state.user,
        admin_create_video_processing_project: state.admin_create_video_processing_project,
        store_video_processing_project: state.store_video_processing_project
    };
};

const actions = {
    handlePopupVisibility: popupDisplayHandlerAction,
    popUpHandler: popupDisplayHandlerAction,
    resetReduxStore: resetReduxStoreAction,
    createVideoProcessingProject: adminCreateVideoProcessingProjectAction,
    storeVideoProcessingProject: storeVideoProcessingProjectAction
};


export default withRouter(connect(mapStateToProps, actions)(MemberVideoProcessingPopup));
