import React, {Component} from 'react'
import {withRouter} from 'react-router-dom'
import {connect} from 'react-redux'
import Logger from '../../../core/util/Logger'
import {popupDisplayHandlerAction} from '../../../actions/popup-display-action'

class VideoProcessorDisabledPopup extends Component {

  render () {
    return (
      <div>
        <div tabIndex="-1" role="dialog"
             style={this.props.popup.data.id === 'video_processor_disabled_popup' && this.props.popup.data.shouldDisplay ? {display: 'block'} : {display: 'none'}}
             className="modal fade show"
             aria-modal="true">
          <div className="overlay">
            <div role="document" className="modal-dialog modal-dialog-scrollable">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 id="exampleModalLabel" className="modal-title text-center">
                    Alert!
                  </h5>
                  <button type="button" data-dismiss="modal" aria-label="Close"
                          onClick={this.onClosePopupHandler.bind(this)}
                          className="close">
                    <span aria-hidden="true">×</span></button>
                </div>
                <div className="modal-body">
                  <div className="alert alert-danger" role="alert">
                    The feature has been discontinued. Please use the new video uploader in creator console.
                  </div>
                </div>
                <div className="modal-footer">
                  <button type="button" data-dismiss="modal"
                          onClick={this.onClosePopupHandler.bind(this)}
                          className="btn btn-secondary">Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  onClosePopupHandler () {
    Logger.info('video processor disabled popup is closed')
    let payload = {
      id: 'video_processor_disabled_popup',
      shouldDisplay: false
    }
    this.props.handlePopupVisibility(payload)
  }
}

const mapStateToProps = (state) => {
  return {
    popup: state.popup
  }
}

const actions = {
  handlePopupVisibility: popupDisplayHandlerAction
}

export default withRouter(connect(mapStateToProps, actions)(VideoProcessorDisabledPopup))
